<template>
  <div class="home container">
    <!-- <div id="home"> -->
    <!--   <h1 class="mt-3"> -->
    <!--     Welcome to -->
    <!--     <br /> -->
    <!--     <strong> -->
    <!--       {{ this?.userIn ? this?.userIn?.business?.name : "" }} -->
    <!--     </strong> -->
    <!--     <br /> -->
    <!--     Clients Platform -->
    <!--   </h1> -->
    <!---->
    <!--   <img alt="Mfuko Plus logo" src="/assets/holder.jpg" class="thumbnail rounded my-2 border border-dark"> -->
    <!--   <h1 class="mb-3"> -->
    <!--     <strong>{{ this?.userIn ? this?.userIn?.biodata?.name : "" }}</strong> -->
    <!--   </h1> -->
    <!--   <p> -->
    <!--     <a href="https://mfuko.net/" target="_blank" rel="noopener">MFUKO PLUS</a> is an integrated Sacco and Micro -->
    <!--     finance Management System with a number of modules.<br> Enable Saccos to manage their operations from loan -->
    <!--     applications to disbursement, Savings accounts functionality,<br>Financial accounting and Reporting, -->
    <!--     shares/Deposits management, Front office administration. -->
    <!--   </p> -->
    <!--   <h4 class="py-2 my-2 border-bottom border-top text-uppercase fw-bold"> -->
    <!--     Summary <span> -->
    <!--       <button type="button" class="btn btn-outline-success btn-sm" @click="refresh"> -->
    <!--         <Tooltip content="Click to refresh for up to date summary" max-width="300" placement="top"> -->
    <!--           <i class="fa fa-refresh"></i> -->
    <!--         </Tooltip> -->
    <!--       </button> -->
    <!--     </span> -->
    <!--   </h4> -->
    <!---->
    <!-- </div> -->


    <!-- large screens  -->
    <div class="d-none d-lg-block d-xl-block">
      <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <div class="w-50">
          <h1 class="navbar-brand" href="#">&nbsp;DASHBOARD</h1>
        </div>

        <div class="w-50 d-flex justify-content-end collapse navbar-collapse" id="navbarSupportedContent">
          <Text type="secondary">Hi {{ userIn?.biodata?.name ?? "Sacco Member" }}</Text>
        </div>
      </nav>

      <Card>
        <!-- <div class="my-2 w-100"> -->
        <!--   <h5 class="">ACCOUNTS</h5> -->
        <!-- </div> -->

        <div v-if="this.getDashboard?.accounts" class="d-flex justify-content-center">
          <!-- <Card v-for="(account, idx) in this?.getDashboard?.accounts" :key="idx" style="text-align:center"> -->
          <!--   <h5 class="">A/C No. <strong>{{ account?.account_number ?? "N/A" }}</strong></h5> -->
          <!--   <p class="">A/C Type: <strong>{{ account?.type ?? "N/A" }}</strong></p> -->
          <!--   <p class=""> -->
          <!--     A/C Balance: <strong>{{ new Intl.NumberFormat('en-US', { -->
          <!--     }).format(account?.balance) -->
          <!--       ?? "N/A" -->
          <!--       }}</strong> </p> -->
          <!-- </Card> -->

          <Grid :border="false" col="1" class="w-50" padding="10px" :hover="false">
            <GridItem class="text-center border-right" v-if="this?.getDashboard?.shares">
              <Card v-for="(account, idx) in this?.getDashboard?.accounts" :key="idx" :dis-hover="true" padding="0">
                <div class="my-2">ACCOUNTS SUMMARY</div>

                <div class="">
                  <Text :style="{ 'display': 'inline' }">ACCOUNT NO.&nbsp;<Title :level="3"
                      :style="{ 'display': 'inline' }">
                      {{ account?.account_number ?? "N/A" }}
                    </Title></Text>
                </div>

                <div class="">
                  <Text :style="{ 'display': 'inline' }">ACCOUNT BAL.&nbsp;<Title :level="2"
                      :style="{ 'display': 'inline' }">
                      {{ new Intl.NumberFormat('en-US', {
                      }).format(account?.balance)
                        ?? "N/A"
                      }}
                    </Title></Text>
                </div>

                <Link to="/accounts">
                View Details
                <Icon type="ios-arrow-round-forward" />
                </Link>
              </Card>
            </GridItem>
          </Grid>
        </div>

        <div v-else style="text-align:center">
          <h5 class="">ACCOUNTS</h5>
          No account information
        </div>


        <Grid :border="false" col="3" padding="10px" :hover="false">

          <GridItem class="text-center border-right" v-if="this?.getDashboard?.shares">
            <Card :dis-hover="true" padding="0">
              <div class="my-2">SHARES SUMMARY</div>

              <div class="">
                <Text :style="{ 'display': 'inline' }">TOTAL SHARES.&nbsp;<Title :level="3"
                    :style="{ 'display': 'inline' }">
                    {{ shareTrans.total_shares ?? 0 }} </Title></Text>
              </div>

              <div class="">
                <Text :style="{ 'display': 'inline' }">TOTAL SHARE VALUE&nbsp;<Title :level="2"
                    :style="{ 'display': 'inline' }">
                    {{ (shareTrans.total_shares * shareTrans.share_price) ?
                      new
                        Intl.NumberFormat('en-US', {
                        }).format((shareTrans.total_shares * shareTrans.share_price),) : "N/A"
                    }}
                  </Title></Text>
              </div>

              <Link to="/shares">
              View Details
              <Icon type="ios-arrow-round-forward" />
              </Link>

            </Card>
          </GridItem>

          <GridItem class="text-center border-left border-right" v-if="this?.getDashboard?.loans">
            <Card :dis-hover="true" padding="0">
              <div class="my-2">LOANS SUMMARY</div>

              <div class="">
                <Text :style="{ 'display': 'inline' }">APPROVED LOANS.&nbsp;<Title :level="3"
                    :style="{ 'display': 'inline' }">
                    {{ this?.getDashboard?.loans?.number_approved ?? "N/A" }} </Title></Text>
              </div>

              <div class="">
                <Text :style="{ 'display': 'inline' }">TOTAL PROCESSED AMOUNT&nbsp;<Title :level="2"
                    :style="{ 'display': 'inline' }">
                    {{ this?.getDashboard?.loans?.total_amount_approved ?
                      new Intl.NumberFormat('en-US', {
                      }).format(this?.getDashboard?.loans?.total_amount_approved)
                      : "N/A"
                    }}
                  </Title></Text>
              </div>

              <Link to="/loans">
              View Details
              <Icon type="ios-arrow-round-forward" />
              </Link>

            </Card>
          </GridItem>



          <GridItem class="text-center" v-if="this?.getDashboard?.transactions">
            <Card :dis-hover="true" padding="0">
              <div class="my-2">TRANSACTIONS SUMMARY</div>

              <div class="">
                <Text :style="{ 'display': 'inline' }">TOTAL TRANSACTIONS&nbsp;<Title :level="3"
                    :style="{ 'display': 'inline' }">
                    {{ this?.getDashboard?.transactions?.total_transactions ?? "N/A" }} </Title></Text>
              </div>

              <div class="">
                <Text :style="{ 'display': 'inline' }">TOTAL TRANSACTED AMOUNT&nbsp;<Title :level="2"
                    :style="{ 'display': 'inline' }">
                    {{ this?.getDashboard?.transactions?.total_transactions_amount ?
                      new Intl.NumberFormat('en-US', {
                      }).format(this?.getDashboard?.transactions?.total_transactions_amount)
                      : "N/A"
                    }} </Title></Text>
              </div>

              <Link to="/transactions">
              View Details
              <Icon type="ios-arrow-round-forward" />
              </Link>

            </Card>
          </GridItem>
        </Grid>

      </Card>

      <!---->
      <!-- <Grid :border="false" col="2" padding="10px" :hover="true"> -->
      <!--   <GridItem class="" v-for="(tran, i) in this.allAccounts" :key="i"> -->
      <!--     <div class="card "> -->
      <!--       <div class="card-body text-dark"> -->
      <!--         <h5 class="card-title">A/C No. <strong>{{ tran.account_number }}</strong> </h5> -->
      <!--         <hr> -->
      <!--         <p class="card-text py-2">A/C Type: <strong>{{ tran.type }}</strong> </p> -->
      <!--         <hr> -->
      <!--         <p class="card-text py-2">A/C Balance: <strong>{{ tran.balance ? new Intl.NumberFormat('en-US', { -->
      <!--           style: 'currency', -->
      <!--           currency: 'UGX' -->
      <!--         }).format(tran.balance,) : "N/A" }}</strong> </p> -->
      <!--       </div> -->
      <!--     </div> -->
      <!--   </GridItem> -->
      <!---->
      <!--   <GridItem class="" v-if="userIn?.member_detail?.member_type == 's' && this.shareTrans"> -->
      <!--     <div class="card "> -->
      <!--       <div class="card-body text-dark"> -->
      <!--         <h5 class="card-title">Shares Section</h5> -->
      <!--         <hr> -->
      <!--         <p class="card-text py-2">Total Shares: <strong>{{ shareTrans.total_shares ?? 0 }}</strong> </p> -->
      <!--         <hr> -->
      <!--         <p class="card-text py-2">Shares Value: <strong>{{ (shareTrans.total_shares * shareTrans.share_price) ? -->
      <!--           new -->
      <!--             Intl.NumberFormat('en-US', { -->
      <!--             }).format((shareTrans.total_shares * shareTrans.share_price),) : "N/A" -->
      <!--             }}</strong> </p> -->
      <!--       </div> -->
      <!--     </div> -->
      <!--   </GridItem> -->
      <!---->
      <!--   <GridItem class="" v-if="this.allLoans"> -->
      <!--     <div class="card "> -->
      <!--       <div class="card-body text-dark"> -->
      <!--         <h5 class="card-title">Loans Section</h5> -->
      <!--         <hr> -->
      <!--         <p class="card-text py-2">No. of Loans: <strong>{{ allLoans.length ?? 0 }}</strong> </p> -->
      <!--         <hr> -->
      <!--         <p class="card-text py-2">Last Update: <strong>{{ new Date().toLocaleString() }}</strong> </p> -->
      <!--       </div> -->
      <!--     </div> -->
      <!--   </GridItem> -->
      <!---->
      <!--   <GridItem class="" v-if="this.allTrans"> -->
      <!--     <div class="card "> -->
      <!--       <div class="card-body text-dark"> -->
      <!--         <h5 class="card-title">Transactions Section</h5> -->
      <!--         <hr> -->
      <!--         <p class="card-text py-2">Total Transactions: <strong>{{ allTrans?.length ?? 0 }}</strong> </p> -->
      <!--         <hr> -->
      <!--         <p class="card-text py-2">Last Update: <strong>{{ new Date().toLocaleString() }}</strong> </p> -->
      <!--       </div> -->
      <!--     </div> -->
      <!--   </GridItem> -->
      <!-- </Grid> -->
      <!---->
    </div>


    <!-- small screens -->
    <div class="d-block d-sm-block d-md-block d-lg-none d-xl-none">
      <div class="w-100 d-flex justify-content-center my-1 collapse navbar-collapse" id="navbarSupportedContent">
        <Text type="secondary">Hi {{ userIn?.biodata?.name ?? "Sacco Member" }}</Text>
      </div>


      <nav class="navbar navbar-expand-lg navbar-light bg-light d-flex justify-content-center">
        <h1 class="navbar-brand" :style="{ 'color': '#03253D' }">&nbsp;DASHBOARD</h1>
      </nav>

      <Row class-name="m-3">
        <Col span="24">
        <Card v-if="this.getDashboard?.accounts" style="text-align:center">
          <template #title>
            <h5 class="">ACCOUNTS</h5>
          </template>
          <Card v-for="(account, idx) in this?.getDashboard?.accounts" :key="idx" style="text-align:center">
            <h5 class="">A/C No. <strong>{{ account?.account_number ?? "N/A" }}</strong></h5>
            <p class="">A/C Type: <strong>{{ account?.type ?? "N/A" }}</strong></p>
            <p class="">
              A/C Balance: <strong>{{ new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'UGX'
              }).format(account?.balance)
                ?? "N/A"
                }}</strong> </p>

            <Link to="/accounts">
            View Details
            <Icon type="ios-arrow-round-forward" />
            </Link>
          </Card>
        </Card>

        <Card v-else style="text-align:center">
          <template #title>
            <h5 class="">ACCOUNTS</h5>
          </template>
          No account information
        </Card>

        </Col>
      </Row>


      <Row class-name="m-3">
        <Col span="24">
        <Card v-if="this?.getDashboard?.shares" style="text-align:center">
          <h5 class="">SHARES</h5>
          <p class="">No. of Shares:<strong>{{ this?.getDashboard?.shares?.total_shares ?? "N/A" }}</strong> </p>
          <p class="">
            Total Share Value:
            <strong>
              {{ this?.getDashboard?.shares?.total_value ?
                new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'UGX'
                }).format(this?.getDashboard?.shares?.total_value)
                : "N/A"
              }}
            </strong>
          </p>

          <Link to="/shares">
          View Details
          <Icon type="ios-arrow-round-forward" />
          </Link>

        </Card>

        <Card v-else style="text-align:center">
          <template #title>
            <h5 class="">SHARES</h5>
          </template>
          No shares information
        </Card>

        </Col>
      </Row>


      <Row class-name="m-3">
        <Col span="24">
        <Card v-if="this?.getDashboard?.loans" style="text-align:center">
          <h5 class="">LOANS</h5>
          <p class="">Loan Requests:&nbsp;<strong>{{ this?.getDashboard?.loans?.number_requested ?? "N/A" }}</strong>
          </p>
          <p class="">Approved Loans:&nbsp;<strong>{{ this?.getDashboard?.loans?.number_approved ?? "N/A" }}</strong>
          </p>
          <p class="">
            Total Requested Amount:
            <strong>
              {{ this?.getDashboard?.loans?.total_amount_requested ?
                new Intl.NumberFormat('en-US', {
                }).format(this?.getDashboard?.loans?.total_amount_requested)
                : "N/A"
              }}
            </strong>
          </p>
          <p class="">
            Total Processed Amount:
            <strong>
              {{ this?.getDashboard?.loans?.total_amount_approved ?
                new Intl.NumberFormat('en-US', {
                }).format(this?.getDashboard?.loans?.total_amount_approved)
                : "N/A"
              }}
            </strong>
          </p>

          <Link to="/loans">
          View Details
          <Icon type="ios-arrow-round-forward" />
          </Link>

        </Card>

        <Card v-else style="text-align:center">
          <template #title>
            <h5 class="">LOANS</h5>
          </template>
          No loan information
        </Card>

        </Col>
      </Row>

      <Row class-name="m-3">
        <Col span="24">
        <Card v-if="this?.getDashboard?.transactions" style="text-align:center">
          <h5 class="">TRANSACTIONS</h5>
          <p class="">Total Transactions: <strong>
              {{ this?.getDashboard?.transactions?.total_transactions ?? "N/A" }}
            </strong> </p>
          <p class="">
            Total Transacted Amount:
            <strong>
              {{ this?.getDashboard?.transactions?.total_transactions_amount ?
                new Intl.NumberFormat('en-US', {
                }).format(this?.getDashboard?.transactions?.total_transactions_amount)
                : "N/A"
              }}
            </strong>
          </p>

          <Link to="/transactions">
          View Details
          <Icon type="ios-arrow-round-forward" />
          </Link>

        </Card>

        <Card v-else style="text-align:center">
          <template #title>
            <h5 class="">TRANSACTIONS</h5>
          </template>
          No transactions information
        </Card>

        </Col>
      </Row>
    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: 'HomeView',
  components: {},
  data() {
    return {
    }
  },
  methods: {
    refresh() {
      this.Shares()
      this.Accounts()
      this.Loans()
      this.Trans()

      this.$store.dispatch("fetchClientDashboard");
    },
    thousandSeparator(m) {
      if (m !== "" || m !== undefined || m !== 0 || m !== "0" || m !== null) {
        return m
          .toString()
          .replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return m;
      }
    },
    ...mapActions({
      Shares: 'fetchShares',
      Accounts: 'fetchAccounts',
      Trans: 'fetchTransactions',
      Loans: 'fetchLoans'
    })
  },
  computed: {
    ...mapGetters([
      'userIn', 'shareTrans', 'allAccounts', 'allLoans', 'allTrans', 'getDashboard'
    ])
  },

  created() {
    this.Shares()
    this.Accounts()
    this.Loans()
    this.Trans()

    this.$store.dispatch("fetchClientDashboard");
  }
}
</script>

<style scoped>
#home {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
