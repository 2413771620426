export default  {
    methods: {
        info(title, message) {
            this.$Notice.info({
                title: title,
                desc: message,
                duration: 5,
            });
        },

        success(title, message) {
            this.$Notice.success({
                title: title,
                desc: message,
                //duration: 0,
            });
        },

        warning(title, message) {
            this.$Notice.warning({
                title: title,
                desc: message,
                duration: 5,
            });
        },

        error(title, message) {
            this.$Notice.error({
                title: title,
                desc: message,
                duration: 5,
            });
        }
    }
}
