<template>
  <div class="page">
    <div v-if="!this.requestSent" class="demo-login">

      <img alt="Logo" class="mb-4" src="../assets/logo.png" width="100">

      <Title :level="3">Reset Password</Title>

      <Login ref="form" @on-submit="handleSubmit">
        <Email name="mail" placeholder="example@email.com" :value="email" :disabled=true />

        <!-- <Poptip trigger="focus" placement="right" width="240"> -->
        <Password name="password" :rules="passwordRule" placeholder="Password of at least 8 characters, case sensitive"
          width="1000" @on-change="handleChangePassword" />
        <template #content>
          <div class="demo-register-tip">
            <div class="demo-register-tip-title" :class="passwordTip.class">
              strength：{{ passwordTip.strong }}
            </div>
            <Progress :percent="passwordTip.percent" hide-info :stroke-width="6" :stroke-color="passwordTip.color" />
            <div class="demo-register-tip-desc">
              Please enter at least 6 characters. Please do not use passwords that are easily guessed. </div>
          </div>
        </template>
        <!-- </Poptip> -->
        <Password name="passwordConfirm" :rules="passwordConfirmRule" placeholder="Confirm Password" />
        <Submit :loading="loading">Update Password</Submit>
      </Login>
    </div>

    <div v-else class="demo-login">
      <img alt="Logo" class="mb-4" src="../assets/logo.png" width="100">
      <Title :level="3">Password Reset Successfully</Title>
      <Paragraph>
        Please sign in with your new credentials
        <Link to="/">
        here
        </Link>.
      </Paragraph>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const validatePassCheck = (rule, value, callback) => {
      if (value !== this.$refs.form.formValidate.password) {
        callback(new Error('The passwords do not match！'));
      } else {
        callback();
      }
    };

    return {
      email: this.$route.query.email,
      loading: false,
      requestSent: false,
      passwordRule: [
        {
          required: true, message: 'password is required', trigger: 'change'
        },
        {
          min: 8, message: 'password must have at least 8 characters', trigger: 'change'
        }
      ],
      passwordConfirmRule: [
        {
          required: true, message: 'enter password again', trigger: 'change'
        },
        { validator: validatePassCheck, trigger: 'change' }
      ],
      passwordLen: 0

    }
  },
  computed: {
    passwordTip() {
      let strong = 'strong';
      let className = 'strong';
      let percent = this.passwordLen > 10 ? 10 : this.passwordLen;
      let color = '#19be6b';


      if (this.passwordLen < 8) {
        strong = 'too short';
        className = 'low';
        color = '#ed4014';
      } else if (this.passwordLen < 10) {
        strong = 'medium';
        className = 'medium';
        color = '#ff9900';
      }

      return {
        strong,
        class: 'demo-register-tip-' + this.passwordLen < 6 ? 'low' : (this.passwordLen < 10 ? 'medium' : 'strong'),
        percent: percent * 10,
        color,
        className,
      }
    }
  },
  methods: {
    handleChangePassword(val) {
      this.passwordLen = val.length;
    },
    handleSubmit(valid, { mail, password }) {
      this.loading = true;

      this.$Loading.start()

      if (valid) {

        this.$store
          .dispatch(
            "resetClientPassword",
            {
              email: mail,
              password: password
            }
          )
          .then(() => {
            this.loading = false;

            this.$Loading.finish();

            this.requestSent = true;

          })
          .catch((err) => {

            this.loading = false;

            this.$Loading.error()

            this.$Message.error(`Error: ${err?.response?.data?.response ?? err?.response?.data?.message ?? err.message}`);

          })

      } else {
        this.loading = false;

        this.$Loading.error()

        this.$Message.error(`Error: Invalid Data`);
      }
    }
  },
  created() {
    this.$watch(
      () => this.$route.query,
      (newURLSearchParams/* , oldURLSearchParams */) => {
        // react to route changes...

        // console.log(newURLSearchParams, oldURLSearchParams)

        this.email = newURLSearchParams?.email;

      }
    )

  },
  mounted() {
    this.email = this.$route.query.email
    // console.log(this.$route.query.email)
  }
}
</script>

<style>
.page {
  width: 100%;
  height: 79vh;
  /* background-color: yellow; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.demo-login {
  width: 50%;
  /* background-color: red; */
  /* margin: 0 auto !important; */
}

.demo-auto-login {
  margin-bottom: 24px;
  text-align: left;
}

.demo-auto-login a {
  float: right;
}

.demo-register {
  width: 400px;
  margin: 0 auto !important;
}

.demo-register .ivu-poptip,
.demo-register .ivu-poptip-rel {
  display: block;
}

.demo-register-tip {
  text-align: left;
}

.demo-register-tip-low {
  color: #ed4014;
}

.demo-register-tip-medium {
  color: #ff9900;
}

.demo-register-tip-strong {
  color: #19be6b;
}

.demo-register-tip-title {
  font-size: 14px;
}

.demo-register-tip-desc {
  white-space: initial;
  font-size: 14px;
  margin-top: 6px;
}
</style>
