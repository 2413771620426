<template>
  <div class="container py-3">
    <div v-if="!shareTrans.share_transactions">
      <h3 class="text-primary text-center">
        <span class="spinner-border spinner-border" role="status" aria-hidden="true"></span>
        Please wait...
      </h3>
    </div>

    <div v-if="shareTrans?.share_transactions">
      <!-- large screens  -->
      <div class="d-none d-lg-block d-xl-block">

        <div class="table-responsive bg-white">
          <nav class="navbar navbar-expand-lg navbar-light bg-light d-flex justify-content-start">
            <h1 class="navbar-brand">&nbsp;SHARE TRANSACTIONS</h1>
          </nav>

          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Date</th>
                <th scope="col">Type</th>
                <th scope="col">No. of Shares</th>
                <th scope="col">Narration</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(share, i) in this.shareTrans.share_transactions" :key="i">
                <td>{{ i + 1 }}. </td>
                <td>{{ share.date }} </td>
                <td> <span v-if="share.buyer">Bought</span> <span v-else>Sold</span> </td>
                <td>{{ share.shares }} shares </td>
                <td>{{ share.narration }} </td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>

      <!-- small screens -->
      <div class="d-block d-sm-block d-md-block d-lg-none d-xl-none">

        <div class="table-responsive bg-white">
          <nav class="navbar navbar-expand-lg navbar-light bg-light d-flex justify-content-center">
            <h1 class="navbar-brand">&nbsp;SHARE TRANSACTIONS</h1>
          </nav>

          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Date</th>
                <th scope="col">Type</th>
                <th scope="col">Shares</th>
                <th scope="col">Narration</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(share, i) in this.shareTrans.share_transactions" :key="i">
                <td>{{ i + 1 }}. </td>
                <td>{{ share.date }} </td>
                <td> <span v-if="share.buyer">Bought</span> <span v-else>Sold</span> </td>
                <td>{{ share.shares }}</td>
                <td>{{ share.narration }} </td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "SharesView",
  data() {
    return {}
  },
  methods: {
    thousandSeparator(m) {
      if (m !== "" || m !== undefined || m !== 0 || m !== "0" || m !== null) {
        return m
          .toString()
          .replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return m;
      }
    },
    ...mapActions({
      Shares: 'fetchShares'
    })
  },
  computed: {
    ...mapGetters([
      'shareTrans'
    ])
  },
  created() {
    this.Shares().then(() => { })
      .catch(err => {
        if (err.code == 'ERR_NETWORK') {
          this.error(err.code, err.message)
        } else {
          this.error(err.code, '')
        }
      })
  }
}
</script>

<style scoped></style>
