<template>
  <div class="container py-3">

    <div v-if="!allTrans">
      <h3 class="text-primary text-center">
        <span class="spinner-border spinner-border" role="status" aria-hidden="true"></span>
        Please wait...
      </h3>
    </div>

    <div v-if="this?.allTrans">
      <!-- large screens  -->
      <div class="d-none d-lg-block d-xl-block">
        <div class="table-responsive bg-white">

          <nav class="navbar navbar-expand-lg navbar-light bg-light d-flex justify-content-start">
            <div class="w-50">
              <h1 class="navbar-brand">&nbsp;TRANSACTIONS</h1>
            </div>

            <div class="w-50 d-flex justify-content-end collapse navbar-collapse" id="navbarSupportedContent">


              <Space wrap>
                <Select size="large" placeholder="Select" v-model="transactionOption" clearable @change="filterTrans"
                  :style="{ 'width': '200px' }">
                  <Option v-for="item in transactionOptions" :value="item" :key="item">{{ item }}</Option>
                </Select>

                <Button @click="downloadTransactions" size="large" :loading="loading" icon="ios-cloud-download-outline">
                </Button>
              </Space>


            </div>
          </nav>

          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Date</th>
                <th scope="col">Amount</th>
                <th scope="col">Type</th>
                <th scope="col">Narration</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(tran, i) in this.allTrans" :key="i">
                <td>{{ i + 1 }}. </td>
                <td>{{ tran.tx_date }}</td>
                <td>{{ new Intl.NumberFormat('en-US', {
                }).format(tran?.reporting_amount) ?? "N/A" }}</td>
                <td>{{ tran.transaction_type }}</td>
                <td class="w-50 text-wrap">{{ tran.narration }}</td>
              </tr>

              <tr v-if="this.allTrans.length < 1">
                <td colspan="5" class="text-center">No Data Available</td>
              </tr>
            </tbody>

            <tfoot>
              <tr>
                <td colspan="7">
                  <Space type="flex">
                    <Page :total="allTrans.length" :page-size="30" v-model="page" show-total
                      @on-change="handleChangePage" />
                  </Space>
                </td>
              </tr>
            </tfoot>

          </table>
        </div>
      </div>


      <!-- small screens -->
      <div class="d-block d-sm-block d-md-block d-lg-none d-xl-none">
        <div class="table-responsive bg-white">
          <!-- <div class="row align-content-center"> -->
          <!--   <div class="col-md-2 d-none d-lg-block"> -->
          <!--     <h1>You're transactionOption</h1> -->
          <!--   </div> -->
          <!--   <div class="col-md-2"> -->
          <!--     <select class="form-control form-control-sm" @change="filterTrans" v-model="transactionOption"> -->
          <!--       <option value="All">All</option> -->
          <!--       <option value="Deposit">Deposit</option> -->
          <!--       <option value="Withdraw">Withdraw</option> -->
          <!--       <option value="Transfer">Transfer</option> -->
          <!--       <option value="Repayment">Loan Repayment</option> -->
          <!--     </select> -->
          <!--   </div> -->
          <!--   <div class="col-md-3 d-none d-lg-block"> -->
          <!--     <h1> Transactions</h1> -->
          <!--   </div> -->
          <!--   <hr class="my-3"> -->
          <!-- </div> -->

          <nav class="navbar navbar-expand-lg navbar-light bg-light d-flex justify-content-start">
            <h1 class="navbar-brand">&nbsp;TRANSACTIONS</h1>
          </nav>

          <Select v-model="transactionOption" clearable @change="filterTrans">
            <Option v-for="item in transactionOptions" :value="item" :key="item">{{ item }}</Option>
          </Select>


          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Date</th>
                <th scope="col">Amount</th>
                <th scope="col">Type</th>
                <th scope="col">Narration</th>
              </tr>
            </thead>

            <tbody>
              <tr v-for="(tran, i) in this.allTrans" :key="i">
                <td>{{ i + 1 }}. </td>
                <td>{{ tran.tx_date }}</td>
                <td><strong>{{ tran.reporting_amount ? new Intl.NumberFormat('en-US', {
                }).format(tran.reporting_amount,) : "N/A" }}</strong> </td>
                <td>{{ tran.transaction_type }}</td>
                <td>{{ tran.narration }}</td>
              </tr>

              <tr v-if="this.allTrans.length < 1">
                <td colspan="5" class="text-center">No Data Available</td>
              </tr>
            </tbody>

            <tfoot>
              <tr>
                <td colspan="7">
                  <Space type="flex">
                    <Page :total="allTrans.length" :page-size="30" v-model="page" show-total
                      @on-change="handleChangePage" />
                  </Space>
                </td>
              </tr>
            </tfoot>

          </table>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "TransactionsView",

  data() {
    return {
      page: 1,
      transactionOption: "All",
      transactionOptions: ["All", "Withdraw", "Transfer", "Repayment"],
    }
  },
  methods: {
    thousandSeparator(m) {
      if (m !== "" || m !== undefined || m !== 0 || m !== "0" || m !== null) {
        return m
          .toString()
          .replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return m;
      }
    },
    filterTrans() {
      //==== ALL TRANSACTIONS =============
      if (this.transactionOption === 'All') {
        this.Trans().then(() => { })
          .catch(err => {
            if (err.code == 'ERR_NETWORK') {
              this.error(err.code, err.message)
            } else {
              this.error(err.code, '')
            }
          })
      }

      //==== DEPOSITS =============
      if (this.transactionOption === 'Deposit') {
        this.Deposits().then(() => { })
          .catch(err => {
            if (err.code == 'ERR_NETWORK') {
              this.error(err.code, err.message)
            } else {
              this.error(err.code, '')
            }
          })
      }
      //========= WITHDRAWS =========
      if (this.transactionOption === 'Withdraw') {
        this.Withdraws().then(() => { })
          .catch(err => {
            if (err.code == 'ERR_NETWORK') {
              this.error(err.code, err.message)
            } else {
              this.error(err.code, '')
            }
          })
      }

      //======= DEPOSITS =============
      if (this.transactionOption === 'Transfer') {
        this.Transfers().then(() => { })
          .catch(err => {
            if (err.code == 'ERR_NETWORK') {
              this.error(err.code, err.message)
            } else {
              this.error(err.code, '')
            }
          })
      }

      //=============
      if (this.transactionOption === 'Repayment') {
        this.Repayments().then(() => { })
          .catch(err => {
            if (err.code == 'ERR_NETWORK') {
              this.error(err.code, err.message)
            } else {
              this.error(err.code, '')
            }
          })
      }
    },
    handleChangePage(value) {

      console.log(value)

      // this.$store
      //     .dispatch('fetchLoanRequests', { page: value })
    },
    downloadTransactions() {
      this.$store
        .dispatch("downloadTransactions")
        .then((response) => {

          const blob = new Blob([response.data], {
            type: response.data.type,
          });

          const url = window.URL.createObjectURL(blob);

          const link = document.createElement("a");

          link.href = url;

          const contentDisposition = response.headers["content-disposition"];
          // console.log(contentDisposition);

          let fileName = "Member Transactions.xlsx";

          if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename="(.+)"/);

            if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
          }

          link.setAttribute("download", fileName);

          document.body.appendChild(link);

          link.click();

          link.remove();

          window.URL.revokeObjectURL(url);

        })
        .catch(() => {
          this.$Message.error("Failed to export transactions")
        })

    },
    ...mapActions({
      Trans: 'fetchTransactions',
      Deposits: 'fetchDeposits',
      Withdraws: 'fetchWithdraws',
      Transfers: 'fetchTransfers',
      Repayments: 'fetchRepayments',
    })

  },
  computed: {
    ...mapGetters([
      'allTrans'
    ])
  },
  created() {
    this.Trans().then(() => { })
      .catch(err => {
        if (err.code == 'ERR_NETWORK') {
          this.error(err.code, err.message)
        } else {
          this.error(err.code, '')
        }
      })
  },
  watch: {
    transactionOption(newTransactionOption/* , oldTransactionOption */) {
      // console.log(newTransactionOption, oldTransactionOption)

      if (newTransactionOption === 'All') {
        return this.$store
          .dispatch("fetchTransactions")
          .then(() => { })
          .catch(() => { })

      }

      if (newTransactionOption === 'Deposit') {
        return this.$store
          .dispatch("fetchDeposits")
          .then(() => { })
          .catch(() => { })

      }

      if (newTransactionOption === 'Withdraw') {
        return this.$store
          .dispatch("fetchWithdraws")
          .then(() => { })
          .catch(() => { })

      }

      if (newTransactionOption === 'Transfer') {
        return this.$store
          .dispatch("fetchTransfers")
          .then(() => { })
          .catch(() => { })

      }

      if (newTransactionOption === 'Repayment') {
        return this.$store
          .dispatch("fetchRepayments")
          .then(() => { })
          .catch(() => { })
      }

    }
  },
}
</script>

<style scoped></style>
