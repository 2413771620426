<template>
  <div class="page">
    <div v-if="!this.requestSent" class="demo-login">

      <img alt="Logo" class="mb-4" src="../assets/logo.png" width="100">

      <Title :level="3">Forgot Password</Title>

      <Paragraph>
        Please input your email address where we will send a link for you to reset your password.
      </Paragraph>

      <Form ref="forgotPasswordForm" :model="forgotPasswordForm" :rules="forgotPasswordFormRules">

        <FormItem prop="email" class="px-l-5">
          <Input type="email" v-model="forgotPasswordForm.email" placeholder="example@email.com">
          <template #prepend>
            <Icon type="ios-mail-outline"></Icon>
          </template>
          </Input>
        </FormItem>

        <FormItem class="d-flex justify-content-start">
          <Button type="primary" @click="handleSubmit('forgotPasswordForm')" :loading="loading">
            <span v-if="!loading">Submit</span>
            <span v-else>Submitting...</span>
          </Button>
        </FormItem>

      </Form>

    </div>

    <div v-else class="demo-login">
      <img alt="Logo" class="mb-4" src="../assets/logo.png" width="100">
      <Title :level="3">Reset Password Email Sent Successfully</Title>
      <Paragraph>
        Please check your mail inbox for a link for you to reset your password.
      </Paragraph>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      requestSent: false,

      forgotPasswordForm: {
        email: "",
      },

      forgotPasswordFormRules: {
        email: [
          {
            required: true,
            message: 'email is required',
            trigger: 'change',
          },
          {
            type: 'email',
            message: 'valid email required!',
            trigger: 'change'
          }
        ],
      },


    }
  },
  methods: {
    handleSubmit(name) {
      this.loading = true;

      this.$Loading.start();

      this.$refs[name].validate((valid) => {
        if (valid) {
          this.$store
            .dispatch(
              "forgotClientPassword",
              {
                email: this.forgotPasswordForm.email,
              }
            )
            .then(() => {
              this.loading = false;

              this.$Loading.finish();

              this.requestSent = true;

            })
            .catch((err) => {

              this.loading = false;

              this.$Loading.error()

              this.$Message.error(`Error: ${err?.response?.data?.response ?? err?.response?.data?.message ?? err.message}`);

            })

        } else {
          this.loading = false;

          this.$Loading.error();

          this.$Message.error('Please enter your email!')
        }
      });

    }
  }
}
</script>

<style>
.page {
  width: 100%;
  height: 79vh;
  /* background-color: yellow; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.demo-login {
  width: 50%;
  /* background-color: red; */
  /* margin: 0 auto !important; */
}

.demo-auto-login {
  margin-bottom: 24px;
  text-align: left;
}

.demo-auto-login a {
  float: right;
}
</style>
