<template>
  <div class="container py-3">
    <div v-if="!userIn">
      <h3 class="text-primary text-center">
        <span class="spinner-border spinner-border" role="status" aria-hidden="true"></span>
        Please wait...
      </h3>
    </div>

    <div v-if="userIn">

      <!-- large screens  -->
      <div class="d-none d-lg-block d-xl-block">

        <div style="text-align: center;" v-if="this.userIn.biodata.photo">
          <img v-if="this.userIn.biodata.photo" :src="this.userIn.base_url + this.userIn.biodata.photo" width="200"
            height="200" class="thumbnail rounded my-3 border border-dark">
          <img v-else alt="Mfuko Plus logo" src="/assets/holder.jpg" class="thumbnail rounded my-3 border border-dark">
          <h1 class="my-3">
            <!-- You're Welcome  -->
            <!-- <strong>{{ this.userIn ? this.userIn.biodata.name : "" }}</strong> -->
            <strong>USER PROFILE</strong>
          </h1>
        </div>

        <nav class="navbar navbar-expand-lg navbar-light bg-light d-flex justify-content-center">
          <Button size="large" type="primary" @click="modal = true">Change Password</Button>
        </nav>


        <div class="row">
          <div class="col-xl-6 mt-2">
            <div class="card">
              <div class="card-header py-3 bg-transparent">
                <div class="card-widgets">
                  <a data-toggle="collapse" href="#biodata" role="button" aria-expanded="false"
                    aria-controls="biodata"><i class="mdi mdi-minus"></i></a>
                </div>
                <h5 class="header-title mb-0">Personal Bio Data</h5>
              </div>

              <div id="biodata" class="collapse show">
                <div class="card-body">
                  <table class="table table-striped">
                    <tbody>
                      <tr>
                        <td><strong> <i class="fas fa-user"></i> Full Name:</strong></td>
                        <td>{{ userIn.biodata.name ?? '------' }}</td>
                      </tr>
                      <tr>
                        <td><strong><i class="fas fa-phone"></i> Phone:</strong></td>
                        <td>+{{ userIn.biodata.contact ?? '------' }}</td>
                      </tr>
                      <tr>
                        <td><strong><i class="fas fa-envelope"></i> Email:</strong></td>
                        <td>{{ userIn.biodata.email ?? '------' }}</td>
                      </tr>
                      <tr>
                        <td><strong><i class="fas fa-clock"></i> Date of Birth:</strong></td>
                        <td>{{ userIn.biodata.dob ?? '------' }}
                        </td>
                      </tr>
                      <tr>
                        <td><strong><i class="fas fa-transgender"></i> Gender:</strong></td>
                        <td>{{ userIn.biodata.gender ?? '------' }}</td>
                      </tr>
                      <tr>
                        <td><strong><i class="fas fa-transgender"></i> Next of Kin:</strong></td>
                        <td>{{ userIn.biodata.nok ?? '------' }}</td>
                      </tr>
                      <tr>
                        <td><strong><i class="fas fa-map-marker"></i> Address :</strong></td>
                        <td>{{ userIn.biodata.location ?? '------' }}</td>
                      </tr>

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- end card-->
          </div>
          <!-- bigdata-->

          <div class="col-xl-6 mt-2">
            <div class="card">
              <div class="card-header py-3 bg-transparent">
                <div class="card-widgets">
                  <a data-toggle="collapse" href="#Work" role="button" aria-expanded="false" aria-controls="Work"><i
                      class="mdi mdi-minus"></i></a>
                </div>
                <h5 class="header-title mb-0">Other Information</h5>
              </div>

              <div id="Work" class="collapse show">
                <div class="card-body">
                  <table class="table table-striped">
                    <tbody>
                      <tr>
                        <td><strong> <i class="mdi mdi-account-card-details"></i> Marital Status :</strong></td>
                        <td>
                          <span v-if="userIn.biodata.marital_status == 1">Single</span>
                          <span v-if="userIn.biodata.marital_status == 2">Married</span>
                          <span v-if="userIn.biodata.marital_status == 3">Divorced</span>
                          <span v-if="userIn.biodata.marital_status == 4">Cohabitation</span>
                          <span v-if="userIn.biodata.marital_status == 5">Widowed</span>
                        </td>
                      </tr>
                      <tr>
                        <td><strong><i class="mdi mdi-office-building"></i> NIN :</strong>
                        </td>
                        <td>{{ userIn.biodata.nin ?? '------' }}</td>
                      </tr>
                      <tr>
                        <td><strong><i class=" mdi mdi-phone-classic"></i> Other Contact :</strong></td>
                        <td>+{{ userIn.biodata.other_contact ?? '------' }}</td>
                      </tr>
                      <tr>
                        <td><strong><i class="mdi mdi-account-badge-alert-outline"></i> Mobile Money
                            Number :</strong></td>
                        <td>{{ userIn.biodata.mobile_money ?? '------' }}</td>
                      </tr>
                      <tr>
                        <td><strong><i class="mdi mdi-timetable"></i> Country:</strong></td>
                        <td>{{ userIn.biodata.country ?? '------' }}</td>
                      </tr>
                      <tr>
                        <td><strong><i class="mdi mdi-timetable"></i> Next of Kin Contacts (1):</strong></td>
                        <td>{{ userIn.biodata.nok_contacts ?? '------' }}</td>
                      </tr>
                      <tr>
                        <td><strong><i class="mdi mdi-timetable"></i> Next of Kin Contacts (2):</strong></td>
                        <td>{{ userIn.biodata.nok_contacts2 ?? '------' }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- end card-->

          </div>
        </div>


      </div>


      <!-- small screens -->
      <div class="d-block d-sm-block d-md-block d-lg-none d-xl-none">
        <nav class="navbar navbar-expand-lg navbar-light bg-light d-flex justify-content-center">
          <h1 class="navbar-brand">PROFILE</h1>
        </nav>

        <nav class="navbar navbar-expand-lg navbar-light bg-light d-flex justify-content-center">
          <Button size="small" type="primary" @click="modal = true">Change Password</Button>
        </nav>

        <div style="text-align: center;" v-if="this.userIn.biodata.photo">
          <img v-if="this.userIn.biodata.photo" :src="this.userIn.base_url + this.userIn.biodata.photo" width="200"
            height="200" class="thumbnail rounded my-3 border border-dark">
          <img v-else alt="Mfuko Plus logo" src="/assets/holder.jpg" class="thumbnail rounded my-3 border border-dark">
          <h1 class="my-3">
            <!-- You're Welcome  -->
            <!-- <strong>{{ this.userIn ? this.userIn.biodata.name : "" }}</strong> -->
            <!-- <strong>USER PROFILE</strong> -->
          </h1>
        </div>

        <div class="row">
          <div class="col-xl-6 mt-2">
            <div class="card">
              <div class="card-header py-3 bg-transparent">
                <div class="card-widgets">
                  <a data-toggle="collapse" href="#biodata" role="button" aria-expanded="false"
                    aria-controls="biodata"><i class="mdi mdi-minus"></i></a>
                </div>
                <h5 class="header-title mb-0">Personal Bio Data</h5>
              </div>

              <div id="biodata" class="collapse show">
                <div class="card-body">
                  <table class="table table-striped">
                    <tbody>
                      <tr>
                        <td><strong> <i class="fas fa-user"></i> Full Name:</strong></td>
                        <td>{{ userIn.biodata.name ?? '------' }}</td>
                      </tr>
                      <tr>
                        <td><strong><i class="fas fa-phone"></i> Phone:</strong></td>
                        <td>+{{ userIn.biodata.contact ?? '------' }}</td>
                      </tr>
                      <tr>
                        <td><strong><i class="fas fa-envelope"></i> Email:</strong></td>
                        <td>{{ userIn.biodata.email ?? '------' }}</td>
                      </tr>
                      <tr>
                        <td><strong><i class="fas fa-clock"></i> Date of Birth:</strong></td>
                        <td>{{ userIn.biodata.dob ?? '------' }}
                        </td>
                      </tr>
                      <tr>
                        <td><strong><i class="fas fa-transgender"></i> Gender:</strong></td>
                        <td>{{ userIn.biodata.gender ?? '------' }}</td>
                      </tr>
                      <tr>
                        <td><strong><i class="fas fa-transgender"></i> Next of Kin:</strong></td>
                        <td>{{ userIn.biodata.nok ?? '------' }}</td>
                      </tr>
                      <tr>
                        <td><strong><i class="fas fa-map-marker"></i> Address :</strong></td>
                        <td>{{ userIn.biodata.location ?? '------' }}</td>
                      </tr>

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- end card-->
          </div>
          <!-- bigdata-->

          <div class="col-xl-6 mt-2">
            <div class="card">
              <div class="card-header py-3 bg-transparent">
                <div class="card-widgets">
                  <a data-toggle="collapse" href="#Work" role="button" aria-expanded="false" aria-controls="Work"><i
                      class="mdi mdi-minus"></i></a>
                </div>
                <h5 class="header-title mb-0">Other Information</h5>
              </div>

              <div id="Work" class="collapse show">
                <div class="card-body">
                  <table class="table table-striped">
                    <tbody>
                      <tr>
                        <td><strong> <i class="mdi mdi-account-card-details"></i> Marital Status :</strong></td>
                        <td>
                          <span v-if="userIn.biodata.marital_status == 1">Single</span>
                          <span v-if="userIn.biodata.marital_status == 2">Married</span>
                          <span v-if="userIn.biodata.marital_status == 3">Divorced</span>
                          <span v-if="userIn.biodata.marital_status == 4">Cohabitation</span>
                          <span v-if="userIn.biodata.marital_status == 5">Widowed</span>
                        </td>
                      </tr>
                      <tr>
                        <td><strong><i class="mdi mdi-office-building"></i> NIN :</strong>
                        </td>
                        <td>{{ userIn.biodata.nin ?? '------' }}</td>
                      </tr>
                      <tr>
                        <td><strong><i class=" mdi mdi-phone-classic"></i> Other Contact :</strong></td>
                        <td>+{{ userIn.biodata.other_contact ?? '------' }}</td>
                      </tr>
                      <tr>
                        <td><strong><i class="mdi mdi-account-badge-alert-outline"></i> Mobile Money
                            Number :</strong></td>
                        <td>{{ userIn.biodata.mobile_money ?? '------' }}</td>
                      </tr>
                      <tr>
                        <td><strong><i class="mdi mdi-timetable"></i> Country:</strong></td>
                        <td>{{ userIn.biodata.country ?? '------' }}</td>
                      </tr>
                      <tr>
                        <td><strong><i class="mdi mdi-timetable"></i> Next of Kin Contacts (1):</strong></td>
                        <td>{{ userIn.biodata.nok_contacts ?? '------' }}</td>
                      </tr>
                      <tr>
                        <td><strong><i class="mdi mdi-timetable"></i> Next of Kin Contacts (2):</strong></td>
                        <td>{{ userIn.biodata.nok_contacts2 ?? '------' }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <!-- end card-->

          </div>
        </div>

      </div>

    </div>
  </div>

  <Modal v-model="modal" title="Change Password" @on-ok="handleSubmit" @on-cancel="modal = false" footer-hide="true">

    <Login ref="form" @on-submit="handleSubmit">

      <Password name="oldPassword" :rules="oldPasswordRule" placeholder="Old Password" />

      <!-- <Poptip trigger="focus" placement="right" width="240"> -->
      <Password name="password" :rules="passwordRule" placeholder="Password of at least 8 characters, case sensitive"
        width="1000" @on-change="handleChangePassword" />
      <template #content>
        <div class="demo-register-tip">
          <div class="demo-register-tip-title" :class="passwordTip.class">
            strength：{{ passwordTip.strong }}
          </div>
          <Progress :percent="passwordTip.percent" hide-info :stroke-width="6" :stroke-color="passwordTip.color" />
          <div class="demo-register-tip-desc">
            Please enter at least 6 characters. Please do not use passwords that are easily guessed. </div>
        </div>
      </template>
      <!-- </Poptip> -->

      <Password name="passwordConfirm" :rules="passwordConfirmRule" placeholder="Confirm Password" />

      <Submit :loading="loading">Update Password</Submit>
    </Login>
  </Modal>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ProfileView",
  data() {
    const validatePassCheck = (rule, value, callback) => {
      if (value !== this.$refs.form.formValidate.password) {
        callback(new Error('The passwords do not match！'));
      } else {
        callback();
      }
    };


    return {
      modal: false,
      loading: false,
      oldPasswordRule: [
        {
          required: true, message: 'old password is required', trigger: 'change'
        },
      ],
      passwordRule: [
        {
          required: true, message: 'new password is required', trigger: 'change'
        },
        {
          min: 8, message: 'password must have at least 8 characters', trigger: 'change'
        }
      ],
      passwordConfirmRule: [
        {
          required: true, message: 'enter new password again', trigger: 'change'
        },
        { validator: validatePassCheck, trigger: 'change' }
      ],
      passwordLen: 0,
    }
  },
  methods: {

    handleChangePassword(val) {
      this.passwordLen = val.length;
    },
    handleSubmit(valid, { oldPassword, password, passwordConfirm }) {
      this.loading = true;

      this.$Loading.start()

      // debugger;

      if (valid) {

        // this.$Message.error(`OP: ${oldPassword} NP: ${password} CP: ${confirmPassword}`);

        this.$store
          .dispatch(
            'resetPassword', {
            old_password: oldPassword,
            new_password: password,
            new_password1: passwordConfirm,
          }
          )
          .then(() => {
            this.loading = false;

            this.$Loading.finish();

            this.$Modal.remove()

            this.modal = false;

            this.$Message.success("Password Changed Successfully");

            // this.$refs.form.formValidate.reset();

            this.$refs.form.formValidate.oldPassword = "";
            this.$refs.form.formValidate.password = "";
            this.$refs.form.formValidate.passwordConfirm = "";

          })
          .catch((err) => {

            this.loading = false;

            this.$Loading.error()

            this.$Message.error(`Error: ${err?.response?.data?.response ?? err?.response?.data?.message ?? err.message}`);

          })

      } else {
        this.loading = false;

        this.$Loading.error()

        this.$Message.error(`Error: Invalid Data`);
      }
    }
  },
  computed: {
    ...mapGetters([
      'userIn'
    ]),
    passwordTip() {
      let strong = 'strong';
      let className = 'strong';
      let percent = this.passwordLen > 10 ? 10 : this.passwordLen;
      let color = '#19be6b';


      if (this.passwordLen < 8) {
        strong = 'too short';
        className = 'low';
        color = '#ed4014';
      } else if (this.passwordLen < 10) {
        strong = 'medium';
        className = 'medium';
        color = '#ff9900';
      }

      return {
        strong,
        class: 'demo-register-tip-' + this.passwordLen < 6 ? 'low' : (this.passwordLen < 10 ? 'medium' : 'strong'),
        percent: percent * 10,
        color,
        className,
      }
    }
  },
}
</script>

<style scoped></style>
