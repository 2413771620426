<template>
    <div class="container py-3">
        <!-- <h4>Loan Request Details</h4> -->
        <div v-if="loading">
            ...loading
        </div>

        <div v-else>
            <div v-if="Object.keys(currentLoanRequest).length < 1">
                No Data Available
            </div>

            <div v-else>
                <Card class="w-100 mt-3">
                    <!-- larger devices -->
                    <div class="d-none d-lg-block">
                        <PageHeader title="Loan Request Details" back @on-back="this?.$router.go(-1)">
                            <template #action>
                                <Button
                                    v-if="currentLoanRequest?.loan_request?.is_approved === 'PENDING' || currentLoanRequest?.loan_request?.is_approved === '0'"
                                    @click="openLoanModal = true" type="default">Edit</Button>
                            </template>
                        </PageHeader>
                    </div>

                    <!-- small devices -->
                    <div class="d-block d-lg-none">
                        <PageHeader title="Loan Request Details" back @on-back="this?.$router.go(-1)">
                            <template #action>
                                <Button
                                    v-if="currentLoanRequest?.loan_request?.is_approved === 'PENDING' || currentLoanRequest?.loan_request?.is_approved === '0'"
                                    @click="openLoanModal = true" type="default">Edit</Button>
                            </template>
                        </PageHeader>
                    </div>


                    <div class="m-5"></div>

                    <Row>
                        <Col span="12">
                        <Space direction="vertical">
                            <p>Request ID</p>
                            <p>Loan Type</p>
                            <p>Duration</p>
                            <p>Amount</p>
                            <p>Last Modified</p>
                            <p>Status</p>
                        </Space>
                        </Col>
                        <Col span="12">
                        <Space direction="vertical" type="flex">
                            <p>&#9;&#9;{{ currentLoanRequest?.loan_request.id ?? "N/A" }}</p>
                            <p>{{ currentLoanRequest?.loan_request?.loan_type?.name ?? "N/A" }}</p>
                            <p>
                                <span>{{ currentLoanRequest?.loan_request?.duration_number ?? "N/A" }}</span>&nbsp;
                                <span>{{ currentLoanRequest?.loan_request?.duration_frequency ?? "N/A" }}</span>
                            </p>
                            <p>{{
                                currentLoanRequest?.loan_request?.amount ?
                                    new Intl.NumberFormat('en-US', {
                                        style: 'currency',
                                        currency: 'UGX'
                                    }).format(currentLoanRequest.loan_request.amount,) : "N/A" }}</p>
                            <p>{{ currentLoanRequest?.loan_request?.date ?
                                moment(currentLoanRequest.loan_request.date).format('Do MMM YYYY, h:mm a') : "N/A" }}
                            </p>
                            <p>{{ currentLoanRequest?.loan_request?.is_approved ?? "N/A" }}</p>
                        </Space>
                        </Col>
                    </Row>


                    <div>
                        <div v-if="this.$store?.state?.user?.guarantors_required_on_loan_request === true">
                            <Divider orientation="left">Guarantors</Divider>
                            <table class="table table-hover">

                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Contact</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr v-for="(guarantor, idx) in currentLoanRequest?.guarantors" :key="idx">
                                        <td>{{ idx + 1 }}. </td>
                                        <td>{{ guarantor?.request_guarantor?.biodata?.name ?? "N/A" }} </td>
                                        <td>{{ guarantor?.request_guarantor?.biodata?.contact ?? "N/A" }}</td>
                                    </tr>
                                </tbody>

                            </table>


                        </div>
                    </div>

                    <div>
                        <div v-if="this.$store?.state?.user?.security_required_on_loan_request === true">

                            <Divider orientation="left">Security</Divider>

                            <table class="table table-hover">

                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Description</th>
                                        <th scope="col">Valuation</th>
                                        <th scope="col">File</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr v-for="(security, i) in currentLoanRequest?.securities" :key="i">
                                        <td>{{ i + 1 }}. </td>
                                        <td>{{ security?.name ?? "N/A" }} </td>
                                        <td>{{ security?.description ?? "N/A" }} </td>
                                        <td>{{ new Intl.NumberFormat('en-US', {
                                            style: 'currency', currency: 'UGX'
                                        }).format(security?.estimated_valuation) }}</td>
                                        <td>
                                            <a :href="fileUrl + security.security" download>{{
                                                security.security.split("loan_request_securities/")[1]
                                                }}</a>
                                        </td>
                                    </tr>
                                </tbody>

                            </table>


                        </div>
                    </div>


                </Card>

                <!-- {{ -->
                <!--     currentLoanRequest -->
                <!-- }} -->
                <!-- {{ -->
                <!--     this.$store.state.user -->
                <!-- }} -->


            </div>
        </div>
    </div>

    <Modal v-model="openLoanModal" title="Update Loan Request" footer-hide=true>
        <Form ref="loanFormValidate" :model="loanFormValidate" label-position="left" :label-width="100"
            :rules="loanRuleValidate">

            <FormItem label="Amount" prop="amount">
                <InputNumber :min="0" v-model="loanFormValidate.amount"
                    :formatter="value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                    :parser="value => value.replace(/\$\s?|(,*)/g, '')" class="w-100" />
            </FormItem>

            <FormItem label="Loan Type" prop="loanType">
                <Select v-model="loanFormValidate.loanType" filterable>
                    <Option v-for="(loanType, idx) in this.getBusinessLoanTypes" :value="loanType.id?.toString()"
                        :key="idx">{{
                            loanType.name + ` - ${loanType.interest_rate}% Interest ${loanType.formula === 4 ? 'Per Day' :
                                loanType.formula === 3 ? 'Per Week' :
                                    loanType.formula === 2 ? 'Per Month' :
                                        'Per Annum'
                            }` }}</Option>
                </Select>
            </FormItem>

            <FormItem label="Duration">
                <Row>
                    <Col span="11">
                    <FormItem prop="durationNumber">

                        <InputNumber :min="0" v-model="loanFormValidate.durationNumber" class="w-100" />
                    </FormItem>
                    </Col>
                    <Col span="2">
                    </Col>
                    <Col span="11">

                    <FormItem prop="durationFrequency">
                        <Select v-model="loanFormValidate.durationFrequency">

                            <Option
                                v-if="loanFormValidate.loanType !== '' && this.getBusinessLoanTypes?.filter((blt) => blt.id === parseInt(loanFormValidate.loanType))[0]['interval'] === 0"
                                value="days">DAYS</Option>
                            <Option
                                v-if="loanFormValidate.loanType !== '' && this.getBusinessLoanTypes?.filter((blt) => blt.id === parseInt(loanFormValidate.loanType))[0]['interval'] === 1"
                                value="weeks">WEEKS</Option>
                            <Option
                                v-if="loanFormValidate.loanType !== '' && this.getBusinessLoanTypes?.filter((blt) => blt.id === parseInt(loanFormValidate.loanType))[0]['interval'] === 2"
                                value="fortnights">FORTNIGHTS</Option>
                            <Option
                                v-if="loanFormValidate.loanType !== '' && this.getBusinessLoanTypes?.filter((blt) => blt.id === parseInt(loanFormValidate.loanType))[0]['interval'] === 3"
                                value="months">MONTHS</Option>
                            <Option
                                v-if="loanFormValidate.loanType !== '' && this.getBusinessLoanTypes?.filter((blt) => blt.id === parseInt(loanFormValidate.loanType))[0]['interval'] === 4"
                                value="years">YEARS</Option>

                        </Select>
                    </FormItem>
                    </Col>
                </Row>
            </FormItem>

            <FormItem>
                <Row>
                    <Col span="5">
                    <Button v-if="!loadingStatus"
                        @click="handleResetUpdateLoanRequest('loanFormValidate')">Reset</Button>
                    </Col>
                    <Col span="1">
                    </Col>
                    <Col span="4">
                    <Button type="primary" @click="handleSubmitUpdateLoanRequest('loanFormValidate')"
                        :loading="loadingStatus">
                        <span v-if="!loadingStatus">Submit</span>
                        <span v-else>Loading...</span>
                    </Button>
                    </Col>
                    <Col span="8">
                    </Col>
                </Row>

            </FormItem>
        </Form>
    </Modal>


</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

export default {
    name: "LoanRequestDetailView",
    data() {
        return {
            queryParams: {},
            loading: false,
            // modal: false,
            openLoanModal: false,
            // openWithdrawModal: false,
            // openDeleteModal: false,

            currentLoanRequest: {},

            loanFormValidate: {
                amount: '',
                durationFrequency: '',
                durationNumber: null,
                loanType: '',
            },

            loanRuleValidate: {

                amount: [
                    { required: true, message: 'Amount must be more than 0', trigger: 'change', type: 'number' }
                ],
                durationNumber: [
                    { required: true, message: 'Field must be more than 0', trigger: 'change', type: 'number', min: 0 }
                ],
                durationFrequency: [
                    { required: true, type: 'string', message: 'Select a valid option', trigger: 'change' }
                ],
                loanType: [
                    { required: true, message: 'Select valid loan type', trigger: 'change' },
                ],
            },
            loadingStatus: false,
            fileUrl: process.env.NODE_ENV === "production" ? "https://app.mfuko.net" : "http://localhost:8000",

        }
    },
    methods: {
        moment: function () {
            return moment();
        },
        handleSubmitUpdateLoanRequest(name) {
            this.$refs[name].validate((valid) => {
                if (valid) {
                    this.$Loading.start()

                    this.loadingStatus = true;
                    // data to be encoded as application/json
                    this.$store
                        .dispatch('updateLoanRequest', { oldData: this.currentLoanRequest?.loan_request, updateData: this.loanFormValidate })
                        .then(() => {
                            this.$Message.success('Successfully updated loan request');

                            this.openLoanModal = false;

                            setTimeout(() => {
                                this.loadingStatus = false;

                                this.$Loading.finish();

                                this.$router.push({ path: '/requests', query: { request_type: 'loan' } })
                            }, 1000);
                        })
                        .catch(err => {
                            this.$Loading.error()

                            this.loadingStatus = false;

                            this.$Message.error(`Error: ${err?.response?.data?.message ?? err.message}`);
                        })
                } else {
                    this.$Message.error('Some fields are missing!');
                }
            })

        },
        handleResetUpdateLoanRequest(name) {
            this.$refs[name].resetFields();
        },

    },
    computed: {
        ...mapGetters([
            'getBusinessLoanTypes'
        ])
    },
    created() {
        this.$watch(
            () => this.$route.params,
            (newURLSearchParams /*, oldURLSearchParams */) => {
                // react to route changes...
                this.queryParams = newURLSearchParams;

                // console.log(newURLSearchParams, oldURLSearchParams)

                this.$store
                    .dispatch('fetchLoanRequest', this.$route.params.id)
                    .then(() => {
                        this.loading = false;
                    })
                    .catch(() => {
                        this.loading = false;

                        // this.$Message.error(`${err}`);
                    })


            }
        )

    },
    mounted() {
        // console.log(this.$route)
        this.loading = true;

        this.$store
            .dispatch('fetchLoanRequest', this.$route.params.id)
            .then((response) => {
                this.loading = false;

                this.currentLoanRequest = response.data.data
            })
            .catch(err => {
                this.loading = false;

                this.$Message.error(`${err}`);
            })

        this.$store
            .dispatch('fetchBusinessLoanTypes')

    }
}
</script>

<style scoped></style>
