import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import LoginView from "../views/LoginView";
import AccountsView from "../views/AccountsView";
import LoansView from "../views/LoansView";
import SharesView from "../views/SharesView";
import TransactionsView from "../views/TransactionsView";
import StatementView from "@/views/StatementView";
import ProfileView from "@/views/ProfileView";
import ChangePassword from "@/views/ChangePassword";
import RequestView from "@/views/RequestView";
import ErrorView from "@/views/errors/ErrorView.vue";
import LoanRequestDetailView from "@/views/LoanRequestDetail";
import SelectBusinessView from "@/views/SelectBusiness";
import ForgotPasswordView from "@/views/ForgotPassword";
import ResetPasswordView from "@/views/ResetPassword";

const routes = [
  {
    path: "/",
    name: "home",
    meta: {
      auth: true,
    },
    component: HomeView, //The component or View
  },
  {
    path: "/login",
    name: "login",
    meta: {
      auth: false,
    },
    component: LoginView, //The component or View
  },
  {
    path: "/select-business",
    name: "selectBusiness",
    meta: {
      auth: true,
    },
    component: SelectBusinessView, //The component or View
  },
  {
    path: "/forgot-password",
    name: "forgotPassword",
    meta: {
      auth: false,
    },
    component: ForgotPasswordView, //The component or View
  },
  {
    path: "/reset-password",
    name: "resetPassword",
    meta: {
      auth: false,
    },
    component: ResetPasswordView, //The component or View
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      auth: false,
    },
    component: LoginView, //The component or View
  },
  {
    path: "/accounts",
    name: "accounts",
    meta: {
      auth: true,
    },
    component: AccountsView, //The component or View
  },
  {
    path: "/loans",
    name: "loans",
    meta: {
      auth: true,
    },
    component: LoansView, //The component or View
  },
  {
    path: "/shares",
    name: "shares",
    meta: {
      auth: true,
    },
    component: SharesView, //The component or View
  },
  {
    path: "/requests",
    name: "requests",
    meta: {
      auth: true,
    },
    params: true,
    component: RequestView, //The component or View
  },
  {
    path: "/loan-request-detail/:id",
    name: "loan-request-detail",
    meta: {
      auth: true,
    },
    params: true,
    component: LoanRequestDetailView, //The component or View
  },
  {
    path: "/transactions",
    name: "transactions",
    meta: {
      auth: true,
    },
    component: TransactionsView, //The component or View
  },
  {
    path: "/statement",
    name: "statement",
    meta: {
      auth: true,
    },
    component: StatementView, //The component or View
  },
  {
    path: "/profile",
    name: "profile",
    meta: {
      auth: true,
    },
    component: ProfileView, //The component or View
  },
  {
    path: "/settings",
    name: "settings",
    meta: {
      auth: true,
    },
    component: ChangePassword, //The component or View
  },
  {
    path: "/:catchAll(.*)",
    component: ErrorView,
    name: "NotFound",
    meta: {
      auth: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkExactActiveClass: "active",
});

//=== Check if Authenticated and if is NOT force redirect to log in screen
router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("token");

  if (to.matched.some((record) => record.meta.auth) && !loggedIn) {
    next("/login");
    return;
  }
  next();
});

//=== Check if Authenticated and if is YES force redirect to previous screen
router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("token");

  if (to.matched.some((record) => !record.meta.auth) && loggedIn) {
    next("/");
    return;
  }
  next();
});

export default router;
