<template>
  <div class="container py-3">
    <div v-if="!this?.allLoans?.results">
      <h3 class="text-primary text-center">
        <span class="spinner-border spinner-border" role="status" aria-hidden="true"></span>
        Please wait...
      </h3>
    </div>

    <div v-if="this?.allLoans?.results">
      <!-- large screens  -->
      <div class="d-none d-lg-block d-xl-block">

        <div class="table-responsive bg-white">
          <nav class="navbar navbar-expand-lg navbar-light bg-light d-flex justify-content-start">
            <h1 class="navbar-brand">&nbsp;LOANS</h1>
          </nav>

          <!-- <Scroll height="500"> -->
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Requested</th>
                <th scope="col">Approved</th>
                <th scope="col">Status</th>
                <th scope="col">Rate</th>
                <th scope="col">Duration</th>
                <th scope="col">Disbursement Date</th>
                <th scope="col">Balance</th>
              </tr>
            </thead>

            <tbody v-if="this?.allLoans?.results?.length < 1">
              <tr>
                <td> No loans yet.</td>
              </tr>
            </tbody>

            <tbody v-else>
              <tr v-for="(loan, i) in this?.allLoans?.results" :key="i">
                <th scope="row">{{ i + 1 }}. </th>
                <td>{{ loan?.amount_requested ?? "N/A" }}</td>
                <td>{{ loan?.amount_approved ?? "N/A" }}</td>
                <td>{{ statusAsString(loan?.loan_status) ?? "N/A" }}
                </td>
                <td>{{ loan?.rate ?? "N/A" }}%</td>
                <td>{{ loan?.requested_duration ?? "N/A" }}&nbsp;{{ intervalAsString(loan?.interval) ?? "N/A" }}
                </td>
                <td>{{ loan?.schedule_start ?? "N/A" }}</td>
                <td v-if="loan?.balance" class="text-danger">{{ loan?.balance }}</td>
                <td v-else>N/A</td>
              </tr>
            </tbody>

            <tfoot v-if="this?.allLoans?.results?.length < 1"></tfoot>

            <tfoot v-else>
              <tr>
                <td colspan="7" class="text-center">
                  <Space type="">
                    <Page :total="this?.allLoans?.total_count" :page-size="30" v-model="loanPage" show-total
                      @on-change="handleChangeLoanPage" />
                  </Space>
                </td>
              </tr>
            </tfoot>

          </table>
          <!-- </Scroll> -->

        </div>


      </div>

      <!-- small screens -->
      <div class="d-block d-sm-block d-md-block d-lg-none d-xl-none">

        <div class="bg-white">
          <nav class="navbar navbar-expand-lg navbar-light bg-light d-flex justify-content-center">
            <h1 class="navbar-brand">&nbsp;LOANS</h1>
          </nav>

          <table class="table table-hover">
            <thead>
              <tr>
                <!-- <th scope="col">#</th> -->
                <!-- <th scope="col">Requested</th> -->
                <!-- <th scope="col">Approved</th> -->
                <!-- <th scope="col">Rate</th> -->
                <!-- <th scope="col">Duration</th> -->
                <!-- <th scope="col">Date</th> -->
                <!-- <th scope="col">Total Balance</th> -->
                <!-- <th scope="col">Summary</th> -->
              </tr>
            </thead>

            <tbody v-if="this?.allLoans?.results?.length < 1">
              <tr>
                <td> No loans yet.</td>
              </tr>
            </tbody>

            <tbody v-else>
              <!-- <tr v-for="(loan, i) in this?.allLoans?.results" :key="i"> -->
              <!--   <th scope="row">{{ i + 1 }}. </th> -->
              <!--   <td>{{ loan.amount_requested }}</td> -->
              <!--   <td>{{ loan.amount_approved }}</td> -->
              <!--   <td>{{ loan.rate }}%</td> -->
              <!--   <td>{{ loan.requested_duration }}</td> -->
              <!--   <td>{{ loan.schedule_start ?? loan.approved_on ?? loan.requested_on }}</td> -->
              <!--   <td> <strong>{{ loan.balance }}</strong> </td> -->
              <!-- </tr> -->

              <div class="w-100" v-for="(loan, idx) in this?.allLoans?.results" :key="idx">
                <div class="border-bottom">
                  <tr class='row d-flex justify-content-center align-items-center'>
                    <!-- <td class="">1. -->
                    <!-- </td> -->
                    <td class="col w-100">
                      <div class="w-100">
                        <div class="d-flex justify-content-between align-items-center mb-1">
                          <div class="">
                            <h6>UGX {{ loan?.amount_approved ?? "N/A" }}</h6>
                          </div>
                          <div class="">
                            {{ loan?.approved_on ?? "N/A" }} </div>

                        </div>
                        <div class="d-flex justify-content-between align-items-center">
                          <div class="text-muted">
                            {{ statusAsString(loan?.loan_status) ?? "N/A" }}
                          </div>
                          <div class="">
                            <Button size="small" type="primary" @click="handleSelectLoan(idx)">
                              {{ "&rarr;" }}
                            </Button>
                          </div>
                        </div>
                        <p v-if="statusAsString(loan?.loan_status) === 'DISBURSED' || statusAsString(loan?.loan_status) === 'TOPPED_UP'"
                          class="text-muted">BALANCE:&nbsp;<span class="text-danger">{{ loan?.balance ?? "N/A" }}</span>
                        </p>

                      </div>
                    </td>

                  </tr>

                </div>

                <div class="container bg-light" v-if="viewLoanDetail && (selectedLoanIndex === idx)">
                  <div class="container">
                    <div class="d-flex justify-content-between align-items-center mb-1">
                      <div class="">
                        Requested
                      </div>
                      <div class="">
                        {{ loan?.amount_requested ?? "N/A" }}
                      </div>
                    </div>

                    <div class="d-flex justify-content-between align-items-center mb-1">
                      <div class="">
                        Approved
                      </div>
                      <div class="">
                        {{ loan?.amount_approved ?? "N/A" }}
                      </div>
                    </div>

                    <div class="d-flex justify-content-between align-items-center mb-1">
                      <div class="">
                        Rate
                      </div>
                      <div class="">
                        {{ loan?.rate ?? "N/A" }}%
                      </div>
                    </div>

                    <div class="d-flex justify-content-between align-items-center mb-1">
                      <div class="">
                        Duration
                      </div>
                      <div class="">
                        {{ loan?.duration ?? "N/A" }}&nbsp;{{ intervalAsString(loan?.interval) ?? "N/A" }}
                      </div>
                    </div>

                    <div class="d-flex justify-content-between align-items-center mb-1">
                      <div class="">
                        Date Disbursed
                      </div>
                      <div class="">
                        {{ loan?.schedule_start ?? "N/A" }}
                      </div>
                    </div>

                    <div class="d-flex justify-content-between align-items-center mb-1">
                      <div class="">
                        Status
                      </div>
                      <div class="">
                        {{ statusAsString(loan?.loan_status) ?? "N/A" }}
                      </div>
                    </div>

                    <div class="d-flex justify-content-between align-items-center mb-1">
                      <div class="">
                        Balance
                      </div>
                      <div class="">
                        {{ loan?.balance ?? "N/A" }}
                      </div>
                    </div>

                  </div>

                </div>
              </div>

            </tbody>

            <tfoot v-if="this?.allLoans?.results?.length < 1"></tfoot>

            <tfoot v-else>
              <tr>
                <td colspan="7" class="text-center">
                  <Space type="">
                    <Page :total="this?.allLoans?.total_count" :page-size="30" v-model="loanPage" show-total
                      @on-change="handleChangeLoanPage" />
                  </Space>
                </td>
              </tr>
            </tfoot>

          </table>
        </div>



      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "LoansView",
  data() {
    return {
      loanPage: 1,
      viewLoanDetail: false,
      selectedLoanIndex: null,
    }
  },
  methods: {
    thousandSeparator(m) {
      if (m !== "" || m !== undefined || m !== 0 || m !== "0" || m !== null) {
        return m
          .toString()
          .replace(/\D/g, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return m;
      }
    },
    handleChangeLoanPage(value) {
      this.$store
        .dispatch('fetchLoans', { page: value })
    },
    handleSelectLoan(idx) {

      this.selectedLoanIndex = idx;

      this.viewLoanDetail = !this.viewLoanDetail;

    },
    statusAsString(statusNumber) {
      let status = null;

      switch (statusNumber) {

        case 0:
          status = 'APPLIED';
          break;

        case 1:
          status = 'APPRAISED';
          break;

        case 2:
          status = 'APPROVED';
          break;

        case 3:
          status = 'DISBURSED';
          break;

        case 4:
          status = 'CLOSED';
          break;

        case 5:
          status = 'REJECTED';
          break;

        case 6:
          status = 'WAIVED_OFF';
          break;

        case 7:
          status = 'WRITTEN_OFF';
          break;

        case 8:
          status = 'TOPPED_UP';
          break;

        default:
          status = null;
          break;
      }

      return status;

    },
    intervalAsString(intervalNumber) {
      let interval = null;

      switch (intervalNumber) {

        case 0:
          interval = 'DAYS';
          break;

        case 1:
          interval = 'WEEKS';
          break;

        case 2:
          interval = 'FORTNIGHT';
          break;

        case 3:
          interval = 'MONTHS';
          break;

        case 4:
          interval = 'YEARS';
          break;

        default:
          interval = null;
          break;
      }

      return interval;

    },
    rateAsString(rateNumber) {
      let rate = null;

      switch (rateNumber) {

        case 0:
          rate = "anum";
          break;

        default:
          rate = null;
          break;
      }

      return rate;

    },
    ...mapActions({
      Loans: 'fetchLoans'
    }),
    moment: function () {
      return moment();
    },
  },
  computed: {
    ...mapGetters([
      'allLoans'
    ])
  },
  created() {
    this.$store
      .dispatch('fetchLoans', { page: this.loanPage })

    // this.Loans({ page: this.loanPage }).then(() => { })
    //   .catch(err => {
    //     if (err.code == 'ERR_NETWORK') {
    //       this.error(err.code, err.message)
    //     } else {
    //       this.error(err.code, '')
    //     }
    //   })
  }
}
</script>

<style scoped></style>
