<template>
<div class="change-password">
  <div class="container py-5">
    <div class="row">
      <div class="col-lg-3"></div>
      <div class="col-xl-6 col-lg-6 wow card pt-3 pb-3">
        <div style="text-align: center;">
          <img alt="Logo" class="mb-4" src="../assets/logo.png" width="100">
          <h1 class="h3 mb-3 fw-normal">Change Password</h1>
        </div>
        <form autocomplete="off" @submit.prevent="changePassword">
          <div class="mb-3">
            <label class="form-label" for="old">Old Password</label>
            <input id="old" v-model="old" autofocus class="form-control border-1 bg-light px-4"
                   placeholder="Enter Old Password" required type="password">
          </div>

          <div class="mb-3">
            <label class="form-label" for="new">New Password
            </label>
            <input v-model="password" id="new" class="form-control border-1 bg-light px-4" placeholder="Enter your New Password"
                   required type="password">
          </div>

          <div class="mb-3">
            <label class="form-label" for="confirm">Confirm Password
            </label>
            <input v-model="confirm" class="form-control border-1 bg-light px-4" id="confirm" placeholder="Confirm your New Password"
                   required type="password">
          </div>


          <div class="row g-3">
            <div class="col-12">
              <button class="btn btn-primary w-100" type="submit">
                Save Changes
                <span v-if="loading" class="spinner-border spinner-border-sm" role="status">
                  <span class="visually-hidden">Loading...</span>
                </span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "ChangePassword",
  data() {
    return {
      old: '',
      password: '',
      confirm: '',
      loading: false,
    }
  },
  methods: {
    changePassword() {
      this.loading = true
      this.$store
          .dispatch('resetPassword', {
            old_password: this.old,
            new_password: this.password,
            new_password1: this.confirm
          })
          .then(() => {
            this.loading = false
            this.success('Process Success', 'New Password updated successfully')
            this.$store.dispatch('SignOut')
          })
          .catch(err => {
            //console.log(err)
            this.loading = false
            if (err.code=='ERR_BAD_REQUEST'){
              this.error('Process Failed', err.response.data.response)
            }
            else if (err.code=='ERR_NETWORK'){
              this.error(err.code, err.message)
            }else {
              this.error(err.code, '')
            }

          })
    }
  }
}
</script>

<style scoped>

</style>